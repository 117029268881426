import  request from "../utils/request";

// 获取注册初始数据， 机构列表和职位列表等
export const getRegisterInit = (params) => {
    return request({
        method: 'get',
        url: '/user/registerInit',
        params
    })
}

// 获取验证码
export const getCode = (data) => {
    return request({
        method: 'post',
        url: `/user/getCode`,
        data
    })
}

// 注册接口
export const register = (data) => {
    return request({
        method: 'post',
        url: `/user/register`,
        data
    })
}

// 获取租户列表
export const getTenantList = () => {
    return request({
        method: 'post',
        url: `/user/GetTenantList`
    })
}