<template>
	<div>
		<div class="content">
			<img src="../../../assets/new-bg.png"
				style="width: 100%;height: 100%;z-index: 1;position: absolute;top: 0;">
			<div class="login animate__animated animate__fadeIn">
				<div class="left">
					<img src="../../../assets/kyx_logo-transparant.png"
						style="width: 128px;margin: 24px 0px 0px 0px;" />
					<div style="margin: 32px 0px 0px 40px; font-size: 28px;">
						<div>欢迎加入</div>
						<div class="sys-name" style="font-weight: bold;"> 科研诚信管理系统 </div>
					</div>
					<div style="margin: 20px 84px 0px 40px; font-size: 12px; color: rgba(0, 0, 0, 0.65);">
						基于区块链技术的科研协作创新平台，为科研工作者提供便捷、专业的信息管理工具，让科研变的更从容。
					</div>
					<img src="../../../assets/register-bg.png"
						style="display: block;width: 352px;margin: 24px 74px 46px 74px;">
				</div>
				<div class="right registerForm">
					<div
						style="font-size: 24px;color: rgba(0, 0, 0, 0.85);font-weight: 550;font-family: PingFang SC Bold;margin: 32px 0px 24px 32px;">
						账户注册</div>
					<a-form-model size="small" ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
						<a-form-model-item label="姓名" has-feedback prop="name">
							<a-input size="large" v-model="ruleForm.name" type="name" placeholder="请输入姓名"
								autocomplete="off">
							</a-input>
						</a-form-model-item>
						<a-form-model-item label="手机号" has-feedback prop="phone">
							<a-input size="large" v-model="ruleForm.phone" placeholder="请输入手机号" autocomplete="off">
							</a-input>
						</a-form-model-item>
						<!-- <a-form-model-item label="邮箱" has-feedback prop="email">
							<a-input size="large" v-model="ruleForm.email" placeholder="请输入有效邮箱地址" autocomplete="off">
							</a-input>
						</a-form-model-item> -->
						<!-- <a-form-model-item label="单位" has-feedback prop="orgId">
							<a-cascader size="large" placeholder="请选择" :options="registerInitData.orgTreeNodes"
								v-model="ruleForm.orgId"
								:field-names="{ label: 'title', value: 'id', children: 'children' }" @change="handleCasChange"/>
						</a-form-model-item> -->
						<a-form-model-item label="邀请码" has-feedback prop="inviteCode">
							<a-input size="large" v-model="ruleForm.inviteCode" placeholder="请输入邀请码"></a-input>
						</a-form-model-item>
						<!-- <a-form-model-item label="职务" prop="posId">
							<a-select v-model="ruleForm.posId" placeholder="请选择职务" style="width: 100%;">
								<a-select-option v-for="(item,index) in registerInitData.posDataList" :value="item.value" :key="item.value" >
									{{ item.text }}
								</a-select-option>
							</a-select>
						</a-form-model-item> -->
						<a-form-model-item label="密码" has-feedback prop="password">
							<a-input-password size="large" v-model="ruleForm.password" placeholder="请输入密码"
								type="password" autocomplete="off">
								<!-- <img src="../../../assets/lock-states.png" slot="prefix" style=""> -->
							</a-input-password>
						</a-form-model-item>
						<a-form-model-item label="确认密码" has-feedback prop="confirmPassword">
							<a-input-password size="large" v-model="ruleForm.confirmPassword" placeholder="请确认密码"
								type="password" autocomplete="off">
								<!-- <img src="../../../assets/lock-states.png" slot="prefix" style=""> -->
							</a-input-password>
						</a-form-model-item>
						<a-form-model-item label="验证码" ref="codeFormItem" colon prop="code" class="smsInput-r">
							<div style="display:flex;">
								<a-input size="large" @blur="() => { $refs.codeFormItem.onFieldBlur() }"
									v-model="ruleForm.code" placeholder="请输入验证码" autocomplete="off">
									<!-- 	<img src="../../../assets/shield-states.png" slot="prefix" style="width: 16px;"> -->
								</a-input>
								<a-button size="large" :disabled="disabled" class="sms-r" @click="sendSms">{{ smsTxt }}
								</a-button>
							</div>
						</a-form-model-item>
						<div style="display: flex;margin-top: 16px;margin-left: 80px;">
							<a-checkbox v-model="isAccepted">我已阅读并接受</a-checkbox>
							<a :href="getHref('userAgreement')" target="_blank"> 《科颐轩服务协议》 </a>
						</div>
					</a-form-model>
					<div style="display: flex;justify-content: flex-end;margin-top: 16px;">
						<a-button :style="{width: '320px', height: '32px', borderRadius: '4px',}"
							type="primary" @click="submitForm" :disabled="loading" :loading="loading">
							注册
						</a-button>
					</div>
					<div style="display: flex; justify-content: center; align-items: center; margin-top: 16px;">
						已有帐号，<a @click="goToLogIn" style="color: #FAAD14;"> 直接登录 </a>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import { setEncrypt } from '../../../tool/encryption.js'
import Footer from '../../../components/common/footer.vue'
import { getCode, register } from '../../../api/register';
import {
	getPublicRsaKey
} from '../../../api/anotherIndex'
import {
		login,
	} from '../../../api/anotherIndex'

import { message } from 'ant-design-vue';
export default {
	components: {
		Footer
	},
	data() {
		return {
			loginType: 1,
			smsTxt: '发送验证码',
			disabled: false,
			smsTime: '',
			ruleForm: {
				name: '',	// 姓名
				password: '',	// 密码
				confirmPassword: '',
				email: '',	// 邮箱
				phone: '',	// 手机
				/* orgId: undefined, // 机构id
				posId: undefined, // 机构名称 */
				code: '',	// 验证码
				inviteCode: '',
			},
			layout: {
				labelCol: {
					span: 5
				},
				wrapperCol: {
					span: 19
				},
			},
			rules: {
				name: [{
					required: true,
					message: '姓名不能为空',
					trigger: 'blur'
				},{
					max: 20,
					trigger: 'blur',
					message: '姓名不能超过20个字符'
				}],
				phone: [{
					required: true,
					validator: this.phoneValidator,
					trigger: 'blur'
				}],
				/* email: [{
					required: true,
					validator: this.emailValidator,
					trigger: 'change'
				}], */
				password: [{
					required: true,
					validator: this.passwordValidator,
					trigger: 'change'
				}],
				confirmPassword: [{
					required: true,
					validator: this.confirmPasswordValidator,
					trigger: 'change'
				}],
				/* orgId: [{
					required: true,
					message: '请选择单位',
					trigger: 'blur'
				}], */
				code: [{
					required: true,
					message: '短信验证码不能为空',
					trigger: 'blur'
				}],
				inviteCode: [{
					required: true,
					whitespace: true,
					message: '邀请码不能为空',
					trigger: 'blur'
				}]
			},
			// 注册初始数据
			registerInitData: {
				orgTreeNodes: [],
				posDataList: []
			},
			// 阅读协议
			isAccepted: true,
			loading: false
		}
	},
	methods: {
		submitForm() {
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					
					if (!this.isAccepted) {
						message.error('请先阅读并接受《科颐轩服务协议》')
						return
					}
					this.loading = true;
					const resd = await getPublicRsaKey()
					if (resd.success) {
						const submitData = JSON.parse(JSON.stringify(this.ruleForm));
						//submitData.orgId = this.ruleForm.orgId[0];
						//const childIds = JSON.parse(JSON.stringify(this.ruleForm.orgId)) ;
						//childIds.splice(0, 1);
						//submitData.childOrgId = childIds.join(',');
						const rsaKey = setEncrypt(resd.data.data, submitData.password)
						submitData.password = rsaKey
						submitData.confirmPassword = setEncrypt(resd.data.data, submitData.confirmPassword);
						const res = await register(submitData);
						if (res.success) {
							//window.localStorage.setItem('Authorization', res.data);
							this.$message.success('注册成功')
							// this.$store.dispatch('callForUserInfo')
							//const rsaKey = setEncrypt(resd.data.data, this.ruleForm.password)
							let data = {
								phoneOrEmail: submitData.phone,
								password: submitData.password,
								RememberPwd: false,
								tenantId: submitData.orgId
							}
							const logInRes = await login(data)
							if (logInRes.success) {
								//this.$message.success('登录成功',2)
								window.localStorage.setItem('Authorization', logInRes.data)
								this.$store.commit('setLoginTimeStamp');
								localStorage.setItem('pro__Access-Token', JSON.stringify({ value: localStorage.getItem('Authorization'), expire: null }));
								localStorage.setItem('pro__X-Access-Token', JSON.stringify({ value: localStorage.getItem('X-Authorization'), expire: null }))
								await this.$store.dispatch('callForUserInfo');
								this.$router.push({
									path: '/core/basicInformation'
								})
							/* 	this.ruleForm.password = ''
								this.ruleForm.confirmPassword = '' */
							} 
						}
					}
					this.loading = false;
					/* this.$refs.codeFormItem.validateState = 'error';
					this.$refs.codeFormItem.validateMessage = '验证码错误' */
				}
			});
		},
		changeLoginType(val) {
			this.loginType = val
		},
		sendSms() {
			this.$refs.ruleForm.validateField(["phone"], async (valid) => {
				if (valid == '') {
					this.smsTime = 60
					let data = {
						"phone": this.ruleForm.phone,
						"codeType": 1,
						//tenantId: this.ruleForm.orgId && this.ruleForm.orgId.length ?  this.ruleForm.orgId[0] : ''
					}
					const res = await getCode(data)
					if (res.code == 200) {
						this.$message.success('验证码已发送，请注意查收')
						this.timer()
					}
				} else {
					console.log('error submit!!');
					return
				}
			})
		},
		timer() {
			if (this.smsTime > 0) {
				this.disabled = true
				this.smsTime--
				this.smsTxt = this.smsTime + "秒再发"
				setTimeout(this.timer, 1000)
			} else {
				this.smsTime = 0
				this.smsTxt = "发送验证码"
				this.disabled = false
			}
		},
		goToRegister() {
			this.$router.push({ path: '/register' })
		},
		goToUpdatePass() {
			this.$router.push({ path: '/updatePass' })
		},
		// 密码校验器
		passwordValidator(_rule, value, callback) {
			if (!value.toString()) {
				callback(new Error('请输入密码'));
				return
			}
			let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+\-={}:;<>,.?])[A-Za-z\d~!@#$%^&*()_+\-={}:;<>,.?]{6,15}/;
			const flag = re.test(value);
			if (flag) {
				callback();
			}
			else {
				callback(new Error('密码必须为6-15位包含大小写字母、数字、和特殊字符, 字符仅支持【~!@#$%^&*()_+-={}:;<>,.?】'));
			}
		},
		// 确认密码校验器
		confirmPasswordValidator(_rule, value, callback) {
			if (!value) {
				callback(new Error('请确认密码'))
			}
			if (value === this.ruleForm.password) {
				callback();
			}
			else {
				callback(new Error('两次输入密码不一致'))
			}
		},
		// 邮箱校验
		emailValidator(_rule, value, callback) {
			if (!value.toString().trim()) {
				callback(new Error('请输入邮箱'))
			}
			const re = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
			if (re.test(value)) {
				callback();
			}
			else {
				callback(new Error('请输入正确邮箱地址'))
			}
		},
		// 手机校验器
		phoneValidator(_rule, value, callback) {
			const re = /^1[3|4|5|7|8|9][0-9]{9}$/;
			if (re.test(value)) {
				callback();
			}
			else {
				callback(new Error("请输入正确手机号"))
			}
		},
		// 跳转登录
		goToLogIn() {
			this.$router.replace({ name: 'login' })
		},
		getHref(name) {
			const href = this.$router.resolve({
				name: name
			});
			return href.href;
		},
		handleCasChange() {
			//console.log(this.ruleForm.orgId);
		}
	},
	async mounted() {
		/* const res = await getRegisterInit();
		if (res.code === 200) {
			this.registerInitData = res.data;
		} */
	}
}
</script>

<style lang="less" scoped>
.content {
	width: 100%;
	min-height: 700px;
	padding: 110px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #3A5BDC;
}

.login {

	//width: 792px;
	//height: 480px;
	overflow: hidden;
	border-radius: 16px;
	background: white;
	box-shadow: 0px 26px 48px 0px rgba(0, 38, 191, 0.25);
	z-index: 20;
	display: flex;

	/deep/ .ant-input.ant-input-lg {
		//background: #EAEEF3;
	}

	/deep/ .ant-select-selection {
		//background: #EAEEF3;
	}
}

.left {
	width: 510px;
	display: flex;
	flex-direction: column;
	padding-left: 24px;
	background: #F7F8FA;
}

.right {
	width: 450px;
	//height: 480px;
	border-radius: 4px;
	background: #FFFFFF;
	padding: 0px 32px 58px 16px;
}

.sms-r {
	width: 88px;
	height: 40px;
	border-radius: 4px;
	background: #F0F5FF;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: @srims-primary-color;
	border-color: transparent !important;
	margin-left: 24px;
}

.sys-name {
	color: @srims-primary-color;
}
</style>
<style lang="less" scoped>
/* .registerForm {
    /deep/ .ant-input, .ant-select-selection {
        background: #F7F8FA !important;
    }
	/deep/ .ant-select-selection {
		background-color: #F7F8FA !important;
	}
}
.registerForm .ant-input {
	//width: 268px !important;
	height: 32px !important;
	border-radius: 4px;
	background: #F7F8FA;
} */

.registerForm .ant-form-item-children-icon {
	left: 230px;
}

.smsInput-r .ant-input {
	//width: 172px !important;
}

.smsInput-r .ant-form-item-children-icon {
	left: 135px !important;
}
</style>