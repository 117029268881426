<template>
	<div >
		<a-row class="content">
			<a-col :span="4" style="text-align: right;">
				<div style="">
					<p style="font-size: 16px;font-weight: 550">新手指南</p>
					<p style="font-size: 14px;"> <a style="text-decoration: none; color: rgba(0, 0, 0, 0.85);" :href="getHref('registerIndtroduction')" target="_blank">注册流程</a> </p>
					<p style="font-size: 14px;"><a style="text-decoration: none; color: rgba(0, 0, 0, 0.85);" :href="getHref('userAgreement')" target="_blank">用户协议</a></p>
					<p style="font-size: 14px;"><a style="text-decoration: none; color: rgba(0, 0, 0, 0.85);" href="/direction/" target="_blank">操作指引</a></p>
				</div>
			</a-col>
			<a-col :span="6" style="text-align: left; padding-left: 98px;">
				<div  style="">
					<p style="font-size: 16px;font-weight: 550">关于我们</p>
					<p style="font-size: 14px;"><a style="text-decoration: none; color: rgba(0, 0, 0, 0.85);" :href="getHref('companyIntroduction')" target="_blank">公司简介</a></p>
					<p style="font-size: 14px;"><a style="text-decoration: none; color: rgba(0, 0, 0, 0.85);" :href="getHref('privacy')" target="_blank"> 隐私政策</a></p>
					<p style="font-size: 14px;"><a style="text-decoration: none; color: rgba(0, 0, 0, 0.85);" :href="getHref('disclaimer')" target="_blank"> 免责声明</a></p>
				</div>
			</a-col>
			<a-col :span="6" style="margin-left: -83px; text-align: left;">
				<div>
					<p style="font-size: 14px;">技术支持：广州市科颐轩电子科技有限公司  © 版权所有</p>
					<!-- <p style="font-size: 14px;">© 版权所有</p> -->
				</div>
				<div  style="">
					<p style="font-size: 14px;">公司地址：广州市天河区高普路金发社区1栋202</p>
					<p style="font-size: 14px;">联系电话：020-38987225</p>
				</div>
			</a-col>
			<a-col :span="6" style="margin-left: 145px;">
				<!-- <img style="width: 100px;" src="../../assets/not.jpg"> -->
				<img style="width: 100px;" :src="qrcode"/>
				<div style="font-size: 14;padding-top: 5px;">安卓客户端下载</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import router from '../../router';
import { useQRCode } from '@vueuse/integrations/useQRCode'
  export default{
	  name:'Footer',
	  /* methods: {
		getHref(name) {
			const href = router.resolve({
				name: name
			});
			return href.href;
		}
	  } */
	  props: {

	  },
	  setup(props, context) {
		const getHref = (name) => {
			const href = router.resolve({
				name: name
			});
			return href.href;
		}
		const qrcode = useQRCode(`${window.location.origin}/app-release.apk`);

		return {
			getHref,
			qrcode
		}
	  }
  } 
</script>

<style scoped>
	.content{
		height: 265px;
		display: flex;
		width: 100%;
		margin: 0 auto;
		padding-top: 6vh;
		background: #FFFFFF;
		color: rgba(0, 0, 0, 0.85);
		text-align: center;
		min-width: 1300px;
	}
</style>
